// @inspired by
// 1. https://stackoverflow.com/questions/68654735/ie11-compatible-object-fromentries
// 2. https://gitlab.com/moongoal/js-polyfill-object.fromentries/-/blob/master/index.js
if (!Object.fromEntries) {
  Object.defineProperty(Object, 'fromEntries', {
    value: function (entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error('Object.fromEntries() requires a single iterable argument')
      }

      var res = {};
      for(var i = 0; i < entries.length; i++) res[entries[i][0]] = entries[i][1];
      return res
    }
  })
}

/**
 * Object.assign() polyfill
 * @reference https://gist.github.com/jrrio/4dd2bec652dd642517a390172be27da2
 */
if (typeof Object.assign != "function") {
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      "use strict";
      if (target == null) {
        throw new TypeError("Cannot convert undefined or null to object");
      }
      var to = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];
        if (nextSource != null) {
          for (var nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

// Object.values polyfill
// https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Object/values#polyfill
if (!Object.values) Object.values = function(obj) {
  if (obj !== Object(obj))
      throw new TypeError('Object.values called on a non-object');
  var val=[],key;
  for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj,key)) {
          val.push(obj[key]);
      }
  }
  return val;
}